export default {
  namespaced: true,

  state: {
    items: [],
  },

  getters: {
    getNotifications: (state: { items: [] }) => state.items,
  },

  mutations: {
    ADD_MESSAGE: (
      state: { items: [] },
      { item }: { item: never },
    ) => {
      state.items.push(item);
    },

    REMOVE_MESSAGE: (
      state: { items: [] },
      { item }: { item: never },
    ) => {
      const index = state.items.indexOf(item);
      if (index !== -1) {
        state.items.splice(index, 1);
      }
    },
  },

  actions: {
    addMessage: (
      { commit }: { commit: (mutationType: string, payload?: any) => void },
      { item }: { item: never },
    ): void => {
      commit('ADD_MESSAGE', { item });
      setTimeout(() => {
        commit('REMOVE_MESSAGE', { item });
      }, 10000);
    },
  },
};
