import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import { get } from '@/services/http';

export function setI18nLanguage(i18n: any, locale: string) {
  const newI18n = { ...i18n };

  if (newI18n.mode === 'legacy') {
    newI18n.global.locale = locale;
  } else {
    newI18n.global.locale.value = locale;
  }

  return newI18n;
}

export function setupI18n(options = {
  legacy: false,
  mode: 'composition',
  fallbackLocale: 'en',
  locale: 'ru',
}) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

export async function loadLocaleMessages(i18n: any, locale: string) {
  const response = await get('language', { language: locale });

  if (response.code === 200) {
    i18n.global.setLocaleMessage(locale, response.data[locale]);
  }

  return nextTick();
}
