export default {
  namespaced: true,

  state: {
    currentProject: null,
  },

  getters: {
    getCurrentProject: (state: { currentProject: object }) => state.currentProject,
  },

  mutations: {
    SET_CURRENT_PROJECT: (
      state: { currentProject: object },
      { currentProject }: { currentProject: object },
    ) => {
      state.currentProject = currentProject;
    },
  },

  actions: {
    changeCurrentProject: (
      { commit }: { commit: (mutationType: string, payload?: any) => void },
      { currentProject }: { currentProject: object },
    ): void => {
      commit('SET_CURRENT_PROJECT', { currentProject });
    },
  },
};
