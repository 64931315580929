<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_6658_206618" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#161E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6 8.00033L7.33333 9.33366L10 6.66699" stroke="#161E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </mask>
    <g mask="url(#mask0_6658_206618)">
      <rect width="16" height="16" fill="currentColor"/>
    </g>
  </svg>
</template>
