import { createApp } from 'vue';
import { loadLocaleMessages, setupI18n, setI18nLanguage } from '@/services/i18n';

import directives from '@/directives';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import 'simplebar';
import 'simplebar/dist/simplebar.css';

const i18n = setupI18n();

const locale = localStorage.getItem('application.locale') || 'ru';
(async () => {
  await loadLocaleMessages(i18n, locale);
  await setI18nLanguage(i18n, locale);
})();

const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n);

directives(app);

app.mount('#app');
