import { createStore } from 'vuex';

import user from '@/store/modules/user';
import project from '@/store/modules/project';
import notifications from '@/store/modules/notifications';

export default createStore({
  modules: {
    user,
    project,
    notifications,
  },
});
