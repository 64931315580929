import Axios from 'axios';
import config from '@/config';

interface Headers {
  'Content-Type': string;
  'X-Auth-Token'?: string;
  'X-Language': string;
}

interface Request {
  url: string;
  method: string;
  headers: object;
  baseURL: any;
  params?: object;
  data?: any;
}

export default async function call(
  method: string,
  url: string,
  payload = {},
  contentType = 'application/json',
) {
  const headers: Headers = {
    'Content-Type': contentType,
    'X-Language': localStorage.getItem('application.locale') || 'ru',
    // 'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const authToken: string|null = localStorage.getItem('ipharma.sponsor.authToken');
  if (authToken) {
    headers['X-Auth-Token'] = authToken;
  }

  const request: Request = {
    url,
    method,
    headers,
    baseURL: config.urls.api,
  };

  if (['GET', 'DELETE'].includes(method)) {
    request.params = payload;
  } else {
    request.data = payload;
  }

  const response = await Axios(request);

  if (response.data) {
    const { data } = response;

    return data;
  }

  return null;
}

export async function get(url: string, payload = {}, contentType = 'application/json') {
  return call('GET', url, payload, contentType);
}

export async function post(url: string, payload = {}, contentType = 'application/json') {
  return call('POST', url, payload, contentType);
}

export async function sendFormData(url: string, payload = {}) {
  const formData = new FormData();
}

export async function remove(url: string, payload = {}, contentType = 'application/json') {
  return call('DELETE', url, payload, contentType);
}
