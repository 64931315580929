export default {
  namespaced: true,

  state: {
    currentUser: null,
    authToken: null,
  },

  getters: {
    getCurrentUser: (state: { currentUser: object }) => state.currentUser,
    getAuthToken: (state: { authToken: string }) => state.authToken,
  },

  mutations: {
    SET_USER_DATA: (
      state: { currentUser: object, authToken: string },
      { currentUser, authToken }: { currentUser: object, authToken: string },
    ) => {
      state.currentUser = currentUser;
      state.authToken = authToken;
    },
  },

  actions: {
    saveUser: (
      { commit }: { commit: (mutationType: string, payload?: any) => void },
      currentUser: object,
    ): void => {
      const token = localStorage.getItem('ipharma.sponsor.authToken');
      commit('SET_USER_DATA', { currentUser, token });
    },

    saveUserData: (
      { commit }: { commit: (mutationType: string, payload?: any) => void },
      { currentUser, authToken }: { currentUser: object, authToken: string },
    ): void => {
      localStorage.setItem('ipharma.sponsor.authToken', authToken);
      commit('SET_USER_DATA', { currentUser, authToken });
    },

    logout: (
      { commit }: { commit: (mutationType: string, payload?: any) => void },
    ): void => {
      localStorage.removeItem('ipharma.sponsor.authToken');
      commit('SET_USER_DATA', { currentUser: null, authToken: null });
    },
  },
};
