import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { get } from '@/services/http';

import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'auth',
    component: () => import('@/views/layouts/AuthLayout.vue'),
    redirect: { name: 'login' },
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/auth/LoginPage.vue'),
      },
      {
        path: 'forgot',
        name: 'forgot',
        component: () => import('@/views/auth/ForgotPage.vue'),
      },
      {
        path: 'recovery',
        name: 'recovery',
        component: () => import('@/views/auth/RecoveryPage.vue'),
      },
      {
        path: 'activate',
        name: 'activate',
        component: () => import('@/views/auth/ActivatePage.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'main-layout',
    component: () => import('@/views/layouts/MainLayout.vue'),
    redirect: { name: 'dashboard' },
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/dashboard/DashboardPage.vue'),
      },
    ],
  },
  {
    path: '/print',
    name: 'print',
    component: () => import('@/views/dashboard/PrintView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('ipharma.sponsor.authToken');

  if ((to.name === 'main-layout' || to.name === 'dashboard') && token === null) {
    next('login');
  }

  if (token) {
    const response = await get('info');
    if (response.code === 200) {
      await store.dispatch(
        'user/saveUserData',
        { currentUser: response.data, authToken: token },
      );

      if ((to.name === 'auth' || to.name === 'login')) {
        next({ name: 'dashboard' });
      }
    } else {
      await store.dispatch('user/logout');
      next({ name: 'login' });
    }
  }

  next();
});

export default router;
